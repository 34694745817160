import axios from 'axios';


export default class OnpremHTTPService {

    static getTenant = async (tenantId: string, token: string) => {
        const headers = {'Authorization': 'Bearer ' + token};
        return axios.get(`/onprem/tenants/${tenantId}`, {headers: headers})
    }

}