import React, {useEffect} from 'react';
import {useAuth, useLoginWithRedirect} from "@frontegg/react";
import './App.css';

import OnpremHTTPService from "./_services/onprem.service";
import {IUser} from "./_interfaces";

const MoveToSuperwise = ({user}: { user: IUser }) => {
    const {tenantId} = user
    OnpremHTTPService.getTenant(tenantId,user.accessToken)
        .then((tenant) => {
            window.location = tenant.data[0].applicationUrl
        })
    return <></>
}

const App = (): JSX.Element => {
    const {user, isAuthenticated} = useAuth();
    const loginWithRedirect = useLoginWithRedirect();

    useEffect(() => {
        if (!isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, loginWithRedirect]);


    return (

        <div className="App">
            {isAuthenticated ? (
                <MoveToSuperwise user={user}/>
            ) : (
                <div>
                </div>
            )}
        </div>
    );
}

export default App;
