import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


import {FronteggProvider} from '@frontegg/react';
import {BrowserRouter} from "react-router-dom";
import EnvironmentConfig from "./environment";

const contextOptions = {
    baseUrl: EnvironmentConfig.AUTH_BASE_URL,
    clientId: EnvironmentConfig.APP_CLIENT_ID
};
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <FronteggProvider contextOptions={contextOptions}>
            <App/>
        </FronteggProvider>
    </BrowserRouter>);